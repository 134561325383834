<template>
  <component
    class="btn"
    :type="type"
    :class="{ 'btn-block': block, [`btn-outline-${color}`]: color }"
    :is="chosenComponent"
    :href="href"
    :to="to"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <slot name="default"></slot>
  </component>
</template>

<script>
import buttonMixin from "@/mixins/buttonMixin";

export default {
  name: "OutlineButton",
  mixins: [buttonMixin]
};
</script>
