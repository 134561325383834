<template>
  <div class="event-details" v-if="event">
    <TitleBold center>{{ $t("event.details.mainInfo") }}</TitleBold>
    <MiddleContainer>
      <div class="row">
        <div class="col col-12 col-md-8 event-data">
          <div v-if="event.event_type && eventNames">
            <span>{{ $t("event.details.type") }}</span>
            <span>{{ eventName(event.event_type).toLowerCase() }}</span>
          </div>
          <!--          <div>-->
          <!--            <span>Администратор</span>-->
          <!--            <span>Аладин Александровч</span>-->
          <!--          </div>-->
          <div>
            <span>{{ $t("event.details.eventDatesRequest") }}</span>
            <span class="minus-gutter">
              <span v-for="(date, key) in eventDatesRequest" :key="key">
                {{ date }}
              </span>
            </span>
          </div>
          <div>
            <span>{{ $t("event.details.eventDates") }}</span>
            <span class="minus-gutter">
              <span v-for="(date, key) in eventDates" :key="key">
                {{ date }}
              </span>
            </span>
          </div>
          <div v-if="event.site">
            <span>{{ $t("event.details.site") }}</span>
            <span
              ><a :href="event.site" target="_blank">{{ event.site }}</a></span
            >
          </div>
          <div>
            <span>{{ $t("event.details.review") }}</span>
            <span v-if="event.review">{{ $t("yes") }}</span>
            <span v-else>{{ $t("no") }}</span>
          </div>
          <div>
            <span>{{ $t("event.details.escort") }}</span>
            <span v-if="event.escort_faces">{{
              $t("event.details.withEscort")
            }}</span>
            <span v-else>{{ $t("event.details.withoutEscort") }}</span>
          </div>
          <div v-if="event.participation_type && eventArticleTypes">
            <span>{{ $t("event.details.participationType") }}</span>
            <span class="comma-gutter">
              <span
                v-for="typeNode in event.participation_type"
                :key="typeNode.id"
                >{{ eventArticleType(typeNode).toLowerCase() }}</span
              >
            </span>
          </div>
        </div>
        <div class="col col-12 col-md-4 event-actions">
          <div
            class="members mb-3"
            v-if="![null, undefined].includes(event.requests_count)"
          >
            <div class="count">{{ event.requests_count }}</div>
            <div class="description">
              {{ $t("event.details.requestsCount") }}
            </div>
          </div>
          <div class="d-grid gap-1">
            <FilledButton
              v-if="event.can_edit"
              block
              class="mb-1"
              color="success"
              :to="{ name: 'EventEdit', params: { slug: eventSlug } }"
              >{{ $t("event.actions.edit") }}
            </FilledButton>
            <template v-if="$store.getters.userIsAdmin">
              <FilledButton
                block
                class="mb-1"
                color="dark"
                v-if="event.requests_count"
                @click="getExportLink"
                >{{ $t("event.actions.export") }}
              </FilledButton>
              <FilledButton
                block
                class="mb-1"
                color="danger"
                v-if="!deleteConfirm"
                @click="() => (deleteConfirm = true)"
                >{{ $t("event.actions.delete") }}
              </FilledButton>
              <div class="btn-group" v-else>
                <FilledButton
                  block
                  class="mb-1"
                  color="danger"
                  @click="deleteEvent"
                  >{{ $t("event.actions.delete") }}
                </FilledButton>
                <FilledButton
                  block
                  class="mb-1"
                  color="secondary"
                  @click="() => (deleteConfirm = false)"
                  >{{ $t("event.actions.cancel") }}
                </FilledButton>
              </div>
            </template>
            <template v-if="event.allowed_request">
              <FilledButton
                v-if="event.application_link"
                block
                class="mb-1"
                color="primary"
                :href="event.application_link"
                >{{ $t("event.actions.apply") }}
              </FilledButton>
              <FilledButton
                v-else
                block
                class="mb-1"
                color="primary"
                :to="{
                  name: 'NewMember',
                  params: { slug: eventSlug },
                  query: sectionSelected ? { section: sectionSelected } : {}
                }"
              >
                {{ $t("event.actions.apply") }}
              </FilledButton>
            </template>
          </div>
        </div>
      </div>
    </MiddleContainer>
    <TitleBold center>{{ $t("event.fields.description") }}</TitleBold>
    <SmallContainer>
      <div v-html="eventDescriptionHtml"></div>
    </SmallContainer>
    <TitleBold center v-if="event.sections && event.sections.length"
      >{{ $t("event.texts.sections") }}
    </TitleBold>
    <MiddleContainer
      class="mb-3"
      v-if="event.sections && event.sections.length"
    >
      <SectionList v-model="sectionSelected" :section-list="event.sections" />
    </MiddleContainer>
    <template v-if="sectionDescriptionHtml">
      <TitleStrip center>{{ $t("event.texts.sectionDescription") }}</TitleStrip>
      <SmallContainer>
        <div v-html="sectionDescriptionHtml"></div>
      </SmallContainer>
    </template>
    <TitleBold
      center
      class="mb-2"
      v-if="![null, undefined].includes(event.requests_count)"
      >{{ $t("event.texts.statistic") }}
    </TitleBold>
    <TitleStrip class="mb-2" v-if="event.requests_count === 0">{{
      $t("event.texts.zeroRequests")
    }}</TitleStrip>
    <title-strip v-if="loadingStatistic">{{ $t("loading") }}</title-strip>
    <MiddleContainer
      class="mb-3"
      v-if="!loadingStatistic && event.requests_count"
    >
      <div v-for="(statistic, key) in statisticNodes" :key="key">
        <TitleStrip class="mb-2">{{ $t(statistic.title) }}</TitleStrip>
        <div
          class="row row-cols-2 row-cols-md-5 justify-content-center"
          v-if="statistic.items && statistic.items.length"
        >
          <StatisticNode
            v-for="(statisticItem, key2) in statistic.items"
            :key="key2"
            :status-id="statisticItem"
            :count="eventStatistic[`${statisticItem}`] || 0"
            class="my-2"
          ></StatisticNode>
        </div>
      </div>
    </MiddleContainer>
    <TitleBold
      center
      class="mb-2"
      v-if="![null, undefined].includes(event.requests_count)"
      >{{ $t("event.texts.members") }}
    </TitleBold>
    <TitleStrip
      class="mb-2"
      v-if="
        ![null, undefined].includes(event.requests_count) &&
          (!eventRequests || !eventRequests.length)
      "
      >{{ $t("event.texts.zeroRequests") }}</TitleStrip
    >
    <div v-if="event.requests_count">
      <title-strip>{{ $t("event.texts.columns") }}</title-strip>
      <div
        class="d-flex justify-content-md-center flex-column flex-md-row flex-md-wrap"
      >
        <CheckBoxInput
          :label="field.title"
          v-model="field.table"
          v-for="field in event.fields.filter(val => !val.hidden)"
          :key="field.id"
          inline
        ></CheckBoxInput>
        <CheckBoxInput
          :label="$t(field.title)"
          v-model="field.value"
          v-for="field in additionalColumns"
          :key="`column_${field.name}`"
          inline
        ></CheckBoxInput>
      </div>
      <title-strip class="mt-4">{{ $t("search") }}</title-strip>
      <div class="d-flex justify-content-center" ref="searchElement">
        <form @submit.prevent="() => (searchNow = searchInput)">
          <RegInput v-model="searchInput" block :label="$t('search')">
            <template v-slot:append>
              <FilledButton type="submit" color="primary">
                <SvgIcon color="current" icon="search" height="16"></SvgIcon>
              </FilledButton>
              <FilledButton
                v-if="searchNow"
                @click="
                  () => {
                    searchNow = null;
                    searchInput = null;
                  }
                "
              >
                {{ $t("event.actions.clear") }}
              </FilledButton>
            </template>
          </RegInput>
        </form>
      </div>
      <title-strip class="mt-4" v-if="eventRequests && eventRequests.length">{{
        $t("event.texts.requestsResults")
      }}</title-strip>
      <title-strip class="mt-4 search-whitespace" v-else>{{
        $t("event.texts.zeroRequests")
      }}</title-strip>
    </div>
    <table
      class="table table-striped table-hover table-mobile"
      v-if="!loadingRequests && eventRequests && eventRequests.length"
    >
      <thead>
        <tr>
          <th scope="col">№</th>
          <th
            scope="col"
            v-for="field in event.fields.filter(val => val.table)"
            :key="field.id"
          >
            {{ field.title }}
          </th>
          <th
            v-if="
              activatedColumns.includes('section') &&
                event.sections &&
                event.sections.length > 1
            "
            scope="col"
          >
            {{ $t("event.texts.section") }}
          </th>
          <th v-if="activatedColumns.includes('requestType')" scope="col">
            {{ $t("event.details.participationType") }}
          </th>
          <th scope="col" v-if="activatedColumns.includes('inbox')">
            <Tooltip :title="$t('event.request.operations.inbox')">
              <SvgIcon icon="inbox-in" color="current" height="16"></SvgIcon>
            </Tooltip>
          </th>
          <th scope="col" v-if="activatedColumns.includes('outbox')">
            <Tooltip :title="$t('event.request.operations.sent')">
              <SvgIcon icon="inbox-out" color="current" height="16"></SvgIcon>
            </Tooltip>
          </th>
          <th scope="col" v-if="activatedColumns.includes('created')">
            {{ $t("event.request.operations.created") }}
          </th>
          <th scope="col" v-if="activatedColumns.includes('updated')">
            {{ $t("event.request.operations.updated") }}
          </th>
          <th scope="col" v-if="activatedColumns.includes('status')">
            {{ $t("event.request.texts.status") }}
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="eventRequest in eventRequests" :key="eventRequest.id">
          <th scope="row">
            <span>{{ $t("event.request.texts.id") }}</span>
            <span class="d-inline-flex align-items-center">
              <Tooltip :title="$t('link')">
                <router-link
                  :to="{ name: 'NoteDetails', params: { id: eventRequest.id } }"
                >
                  {{ eventRequest.id }}
                </router-link>
              </Tooltip>
              <avatar
                :src="eventRequest.user.avatar_small"
                class="ms-2"
                size="32px"
              ></avatar>
            </span>
          </th>
          <td
            v-for="field in event.fields.filter(val => val.table)"
            :key="`${eventRequest.id}_${field.id}`"
          >
            <span>{{ field.title }}</span>
            <span>
              <template
                v-if="
                  field.resourcetype === 'FileCF' &&
                    searchField(eventRequest, field) !== $t('noValue')
                "
              >
                <Tooltip :title="$t('event.request.texts.downloadFile')">
                  <a :href="searchField(eventRequest, field)" target="_blank">
                    <SvgIcon
                      color="current"
                      icon="download"
                      height="16"
                      hover="primary"
                    ></SvgIcon>
                  </a>
                </Tooltip>
              </template>
              <template v-else-if="field.resourcetype === 'TextCF'">
                <p
                  v-for="(textNode, key) in (
                    searchField(eventRequest, field) || $t('noValue')
                  ).split(/[\n\r]/)"
                  :key="`text_node_${field.id}_${key}`"
                >
                  {{ textNode }}
                </p>
              </template>
              <template v-else>
                {{ searchField(eventRequest, field) }}
              </template>
            </span>
          </td>
          <td
            v-if="
              activatedColumns.includes('section') &&
                event.sections &&
                event.sections.length > 1
            "
          >
            <span>{{ $t("event.texts.section") }}</span>
            <span>{{
              event.sections.find(val => val.id === eventRequest.section).title
            }}</span>
          </td>
          <td v-if="activatedColumns.includes('requestType')">
            <span>{{ $t("event.details.participationType") }}</span>
            <span v-if="eventRequest.participation_type">
              {{ eventArticleType(eventRequest.participation_type) }}
            </span>
            <span v-else>
              Не указана
            </span>
          </td>
          <td v-if="activatedColumns.includes('inbox')">
            <span>{{ $t("event.request.operations.inbox") }}</span>
            <span v-if="eventRequest.unread_for_admin_count">
              <Tooltip :title="$t('event.request.operations.unread')">
                <SvgIcon icon="envelope" color="warning" height="16"></SvgIcon>
                {{ eventRequest.unread_for_admin_count }}
              </Tooltip>
            </span>
            <span v-else
              ><Tooltip :title="$t('event.request.operations.read')"
                ><SvgIcon
                  icon="envelope-open"
                  color="success"
                  height="16"
                ></SvgIcon></Tooltip
            ></span>
          </td>
          <td v-if="activatedColumns.includes('outbox')">
            <span>{{ $t("event.request.operations.sent") }}</span>
            <span v-if="eventRequest.unread_for_user_count">
              <Tooltip :title="$t('event.request.operations.unread')">
                <SvgIcon icon="envelope" color="warning" height="16"></SvgIcon>
                {{ eventRequest.unread_for_user_count }}
              </Tooltip>
            </span>
            <span v-else
              ><Tooltip :title="$t('event.request.operations.read')"
                ><SvgIcon
                  icon="envelope-open"
                  color="success"
                  height="16"
                ></SvgIcon></Tooltip
            ></span>
          </td>
          <td v-if="activatedColumns.includes('created')">
            <span>{{ $t("event.request.operations.created") }}</span>
            <span>{{
              new Date(eventRequest.created).toLocaleString(language)
            }}</span>
          </td>
          <td v-if="activatedColumns.includes('updated')">
            <span>{{ $t("event.request.operations.updated") }}</span>
            <span>{{
              new Date(eventRequest.updated).toLocaleString(language)
            }}</span>
          </td>
          <td v-if="activatedColumns.includes('status')">
            <span>{{ $t("event.request.texts.status") }}</span>
            <Tooltip :title="requestStatus(eventRequest.status)"
              ><span class="d-inline d-md-none"
                >{{ requestStatus(eventRequest.status) }}
              </span>
              <SvgIcon
                :icon="`event/${requestStatusIcon(eventRequest.status)}`"
                width="32"
              ></SvgIcon
            ></Tooltip>
          </td>
          <td>
            <span>{{ $t("linkBlank") }}</span>
            <span>
              <Tooltip :title="$t('linkBlank')">
                <router-link
                  :to="{ name: 'NoteDetails', params: { id: eventRequest.id } }"
                  target="_blank"
                >
                  <SvgIcon
                    color="current"
                    icon="external-link"
                    height="16"
                    hover="primary"
                  ></SvgIcon>
                </router-link>
              </Tooltip>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <title-strip v-if="loadingRequests" class="search-whitespace">{{
      $t("loading")
    }}</title-strip>
    <div
      class="d-flex justify-content-center"
      v-if="pageCount && pageCount > 1"
    >
      <div class="btn-group">
        <OutlineButton
          color="primary"
          @click="() => changePage(1)"
          :class="{ active: this.pageNow === 1 }"
        >
          1
        </OutlineButton>
        <OutlineButton color="primary" class="disabled" v-if="pageNow > 3">
          ...
        </OutlineButton>
        <OutlineButton
          v-if="pageNow - 1 > 1"
          color="primary"
          @click="() => changePage(pageNow - 1)"
        >
          {{ pageNow - 1 }}
        </OutlineButton>
        <OutlineButton
          v-if="pageNow > 1 && pageNow < pageCount"
          color="primary"
          @click="() => changePage(pageNow)"
          :class="{ active: this.pageNow === pageNow }"
        >
          {{ pageNow }}
        </OutlineButton>
        <OutlineButton
          v-if="pageNow + 1 < pageCount"
          color="primary"
          @click="() => changePage(pageNow + 1)"
        >
          {{ pageNow + 1 }}
        </OutlineButton>
        <OutlineButton
          color="primary"
          class="disabled"
          v-if="pageNow < pageCount - 2"
        >
          ...
        </OutlineButton>
        <OutlineButton
          color="primary"
          @click="() => changePage(pageCount)"
          :class="{ active: this.pageNow === pageCount }"
        >
          {{ pageCount }}
        </OutlineButton>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import TitleBold from "../components/base/TitleBold";
import MiddleContainer from "../components/base/MiddleContainer";
import FilledButton from "../components/buttons/FilledButton";
import SmallContainer from "../components/base/SmallContainer";
import TitleStrip from "../components/base/TitleStrip";
import StatisticNode from "../components/events/StatisticNode";
import OutlineButton from "../components/buttons/OutlineButton";
import SectionList from "../components/events/SectionList";
import RegInput from "../components/inputs/RegInput";
import API from "../common/api/api.functions";
import showdown from "showdown";
import { mapActions, mapGetters } from "vuex";
import loadingMixin from "../mixins/loadingMixin";
import SvgIcon from "../components/base/SvgIcon";
import CheckBoxInput from "../components/inputs/CheckBoxInput";
import Tooltip from "../components/base/Tooltip";
import Avatar from "../components/base/Avatar";

export default {
  name: "EventDetails",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    Avatar,
    Tooltip,
    CheckBoxInput,
    RegInput,
    SectionList,
    OutlineButton,
    StatisticNode,
    TitleStrip,
    SmallContainer,
    FilledButton,
    MiddleContainer,
    TitleBold,
    SvgIcon
  },
  mixins: [loadingMixin],
  data: () => ({
    event: null,
    eventRequests: null,
    deleteConfirm: false,
    eventStatistic: {},
    statisticNodes: [
      {
        title: "event.texts.requests",
        items: [1, 3, 0, 15, 14, 10]
      },
      {
        title: "event.texts.articles",
        items: [4, 2, 5, 6, 7, 8, 9, 10, 11, 12]
      }
    ],
    mdToHtml: null,
    pageSize: 15,
    pageNow: 1,
    filteredRequestsCount: 0,
    searchInput: null,
    searchNow: null,
    additionalColumns: [
      {
        name: "section",
        title: "event.texts.section",
        value: true
      },
      {
        name: "requestType",
        title: "event.details.participationType",
        value: true
      },
      {
        name: "inbox",
        title: "event.request.operations.inbox",
        value: true
      },
      {
        name: "outbox",
        title: "event.request.operations.sent",
        value: true
      },
      {
        name: "created",
        title: "event.request.operations.created",
        value: true
      },
      {
        name: "updated",
        title: "event.request.operations.updated",
        value: false
      },
      {
        name: "status",
        title: "event.request.texts.status",
        value: true
      }
    ],
    loadingRequests: false,
    loadingStatistic: false
  }),
  methods: {
    ...mapActions(["fieldToFront", "fieldToBack"]),
    async getExportLink() {
      if (this.$store.getters.userIsAdmin) {
        await API.getEventExportLink(this.eventSlug)
          .then(async r => {
            window.open(r.data.url, "_self");
            this.$toast.success("Экспорт начался");
          })
          .catch(e =>
            this.$toast.error(
              e.response?.data
                ? JSON.stringify(e.response.data)
                : "Неизвестная ошибка"
            )
          );
      }
    },
    async deleteEvent() {
      if (this.$store.getters.userIsAdmin) {
        await API.eventDelete(this.eventSlug)
          .then(async () => {
            this.$toast.success("Мероприятие успешно удалено");
            await this.$router.push({ name: "Home" });
          })
          .catch(e =>
            this.$toast.error(
              e.response?.data
                ? JSON.stringify(e.response.data)
                : "Неизвестная ошибка"
            )
          );
      }
      this.deleteConfirm = false;
    },
    searchField(eventRequest, field) {
      let val = eventRequest.contents.find(val => val.cf === field.id);
      if (!val) return this.$t("noValue");
      if (val.value) return val.display_value;
      return this.$t("noValue");
    },
    updateTitle() {
      this.$store.commit(
        "UPDATE_TAB_TITLE",
        `${
          this.eventNames.find(val => val.value === this.event.event_type).label
        } - ${this.event.title}`
      );
    },
    async changePage(page) {
      this.loadingRequests = true;
      this.pageNow = page;
      await this.loadRequests();
      this.loadingRequests = false;
    },
    async loadRequests() {
      let eventRequests = await API.getEventInnerRequestList(
        this.eventSlug,
        this.pageNow,
        this.pageSize,
        {
          section: this.sectionSelected,
          search: this.searchNow
        }
      )
        .then(res => res.data)
        .catch(error => {
          this.$toast.error(JSON.stringify(error?.response?.data || {}));
          // this.notFound = true;
        });
      this.filteredRequestsCount = eventRequests.count || 0;
      this.eventRequests = eventRequests.results || [];
    },
    async loadStatistic() {
      let eventStatistic = await API.getEventStatistic(this.eventSlug, {
        section: this.sectionSelected,
        search: this.searchNow
      })
        .then(res => res.data)
        .catch(error => {
          this.$toast.error(JSON.stringify(error?.response?.data || {}));
          // this.notFound = true;
        });
      eventStatistic = eventStatistic || [];
      const eventStatisticNew = {};
      if (eventStatistic && eventStatistic.length)
        for (const statisticNode of eventStatistic) {
          eventStatisticNew[statisticNode.status] = statisticNode.quantity;
        }
      this.eventStatistic = eventStatisticNew;
    },
    async reloadRequests() {
      if (this.event.show_full) {
        this.loadingStatistic = this.loadingRequests = true;
        this.pageNow = 1;
        await Promise.all([this.loadRequests(), this.loadStatistic()]);
        this.loadingStatistic = this.loadingRequests = false;
      }
    },
    sectionCheck(val) {
      if (val && !(this.event?.sections || []).find(v => v.id === val))
        this.notFound = true;
    }
  },
  computed: {
    ...mapGetters([
      "eventNames",
      "eventArticleTypes",
      "eventName",
      "eventArticleType",
      "requestStatus",
      "requestStatusIcon",
      "language"
    ]),
    sectionSelected: {
      get() {
        const section = this.$route.query?.section;
        return section ? parseInt(section) : null;
      },
      async set(val) {
        const routerObj = {};
        const query = { ...(this.$route.query || {}) };
        if (val) query.section = `${val}`;
        else if (query.section) delete query.section;
        routerObj.query = query;
        await this.$router.replace(routerObj);
      }
    },
    activatedColumns() {
      return this.additionalColumns
        .filter(val => val.value)
        .map(val => val.name);
    },
    pageCount() {
      if (!this.filteredRequestsCount) return 0;
      return Math.ceil(this.filteredRequestsCount / this.pageSize);
    },
    eventDescriptionHtml() {
      if (this.mdToHtml) {
        return this.mdToHtml.makeHtml(this.event.description);
      }
      return null;
    },
    sectionDescriptionHtml() {
      if (this.mdToHtml && this.event?.sections && this.sectionSelected) {
        let section = this.event.sections.find(
          val => val.id === this.sectionSelected
        );
        if (section.description)
          return this.mdToHtml.makeHtml(section.description);
      }
      return null;
    },
    eventSlug() {
      return this.$route.params.slug || null;
    },
    eventDates() {
      let outDates = [
        new Date(this.event.date_begin_event).toLocaleDateString(this.language),
        new Date(this.event.date_end_event).toLocaleDateString(this.language)
      ];
      if (outDates[0] === outDates[1]) return [outDates[0]];
      return outDates;
    },
    eventDatesRequest() {
      let outDates = [
        new Date(this.event.date_begin_request).toLocaleDateString(
          this.language
        ),
        new Date(this.event.date_end_request).toLocaleDateString(this.language)
      ];
      if (outDates[0] === outDates[1]) return [outDates[0]];
      return outDates;
    }
  },
  watch: {
    async sectionSelected(val) {
      this.sectionCheck(val);
      await this.reloadRequests();
    },
    async searchNow() {
      await this.reloadRequests();
      this.$nextTick(() =>
        this.$refs.searchElement.scrollIntoView({
          block: "start",
          behavior: "smooth"
        })
      );
    }
  },
  async mounted() {
    this.loading++;
    this.mdToHtml = new showdown.Converter();
    this.event = await API.getEventDetails(this.eventSlug)
      .then(res => res.data)
      .catch(error => {
        this.$toast.error(JSON.stringify(error?.response?.data || {}));
        this.notFound = true;
        this.loading--;
      });

    this.sectionCheck(this.sectionSelected);
    if (this.notFound) return;
    if (!this.event || !this.event.slug) {
      this.notFound = true;
      this.loading--;
    }

    this.$store.commit("UPDATE_HEADER_TITLE", this.event.title || null);

    const modifiedFields = [];
    for (const field of this.event.fields) {
      modifiedFields.push(this.fieldToFront({ field }));
    }
    this.fields = await Promise.all(modifiedFields);
    this.$store.commit("UPDATE_HEADER_IMAGE", this.event.big_image);

    this.updateTitle();
    this.loading--;
    await this.reloadRequests();
  }
};
</script>

<style lang="scss">
.event-details {
  .event-data {
    font-size: 20px;

    @include media-breakpoint-down("md") {
      font-size: 1rem;
    }

    & > div {
      margin-bottom: 9px;

      & > span {
        &:first-child {
          font-weight: bold;

          &::after {
            content: ": ";
          }
        }
      }
    }
  }

  .event-actions {
    font-weight: bold;
    color: theme-color("grey");

    & > .members {
      text-align: center;

      & > .count {
        $font-size: 76px;

        font-size: $font-size;
        line-height: $font-size;
        opacity: 0.6;
      }

      & > .description {
        font-size: 11px;
        text-transform: uppercase;
      }
    }
  }
}
</style>
