<template>
  <div class="statistic-node">
    <div v-if="title">{{ title }}</div>
    <div class="w-100" v-if="icon">
      <SvgIcon :icon="`event/${icon}`" width="65"></SvgIcon>
    </div>
    <div>{{ count }}</div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon";
import { mapGetters } from "vuex";
export default {
  name: "StatisticNode",
  components: { SvgIcon },
  props: {
    statusId: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    ...mapGetters(["requestStatusIcon", "requestStatus"]),
    icon() {
      let icon = this.requestStatusIcon(this.statusId);
      if (!icon) return;
      return icon;
    },
    title() {
      let title = this.requestStatus(this.statusId);
      if (!title) return;
      return title;
    }
  }
};
</script>

<style lang="scss">
.statistic-node {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 180px;

  & > div {
    &:nth-child(1) {
      font-size: 14px;
      text-transform: uppercase;
      flex: 1 0 0;
    }

    &:nth-child(2) {
      width: 61px;
      height: 61px;
      flex: 0 0 0;
    }

    &:nth-child(3) {
      font-size: 50px;
      flex: 0 0 0;
    }
  }
}
</style>
