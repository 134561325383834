<template>
  <button
    class="btn section btn-block btn-outline-primary"
    :class="{
      'opacity-50': section.deleted
    }"
    v-on="$listeners"
  >
    <div class="title">{{ section.title }}</div>
    <div class="description">{{ section.short_description }}</div>
  </button>
</template>

<script>
export default {
  name: "Section",
  props: {
    section: {
      type: Object,
      required: true
    }
  }
};
</script>
