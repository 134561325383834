<template>
  <div class="row row-cols-1 row-cols-md-4 g-2 justify-content-center">
    <div
      class="col d-flex justify-content-center"
      v-for="(section, key) in sectionList"
      :key="key"
      @click="() => chooseSection(section.id)"
    >
      <Section :section="section" :class="{ active: section.id === value }" />
    </div>
  </div>
</template>

<script>
import Section from "./Section";
export default {
  name: "SectionList",
  components: { Section },
  props: {
    sectionList: {
      type: Array,
      default: () => []
    },
    value: {}
  },
  methods: {
    chooseSection(id) {
      this.value === id ? this.$emit("input", null) : this.$emit("input", id);
    }
  }
};
</script>
